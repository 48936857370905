import NextHead from 'next/head'

export const Head = ({ title, description }: HeadProps) => {
  const env = process.env.NEXT_PUBLIC_ENV || 'development'

  return (
    <NextHead>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="icon" href={`/assets/${env}/favicon/favicon.ico`} type="image/x-icon" />
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href={`/assets/${env}/favicon/apple-icon-57x57.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href={`/assets/${env}/favicon/apple-icon-60x60.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href={`/assets/${env}/favicon/apple-icon-72x72.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href={`/assets/${env}/favicon/apple-icon-76x76.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href={`/assets/${env}/favicon/apple-icon-114x114.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={`/assets/${env}/favicon/apple-icon-120x120.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href={`/assets/${env}/favicon/apple-icon-144x144.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={`/assets/${env}/favicon/apple-icon-152x152.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`/assets/${env}/favicon/apple-icon-180x180.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href={`/assets/${env}/favicon/android-icon-192x192.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`/assets/${env}/favicon/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href={`/assets/${env}/favicon/favicon-96x96.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`/assets/${env}/favicon/favicon-16x16.png`}
      />
      <link rel="manifest" href={`/assets/${env}/favicon/manifest.json`} />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content={`/assets/${env}favicon/ms-icon-144x144.png`} />
      <meta name="theme-color" content="#ffffff" />
    </NextHead>
  )
}

type HeadProps = {
  title: string
  description: string
}
