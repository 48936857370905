import { removeCookies } from 'cookies-next'
import dayjs from 'dayjs'
import router from 'next/router'

import type { ErrorType } from '../codegen/AxiosInstance'

export type TSAErrorType = ErrorType<{
  message: string
  content: { fieldError: string; fieldErrors: Record<string, { message: string }> }
}>

export const AuthHelper = {
  AUTH_CLIENT_TOKEN_NAME: 'tsa-client-token',
  AUTH_COOKIE_NAME: 'tsa-token',
  AUTH_COOKIE_OPTIONS: {
    secure: true,
    expires: dayjs().add(30, 'day').toDate(),
  },
  getErrorMessage: (error: TSAErrorType, customMessage?: string): string => {
    if (error) {
      const { status, data } = error?.response ?? {}
      const dataMessage = `Status ${status} | ${data?.message as string}`

      if (!status) {
        return error.message
      }

      if (data?.content?.fieldError) {
        return `${dataMessage}<br /><br />${customMessage ?? data?.content.fieldError}`
      }

      if (data?.content?.fieldErrors) {
        const fieldErrorsKeys = Object.keys(data?.content.fieldErrors)
        return `${dataMessage}<br /><br />${
          customMessage ??
          fieldErrorsKeys.map(fek => data?.content?.fieldErrors[fek].message).join(' / ')
        }`
      }

      return dataMessage
    }

    return 'Undefined error occured'
  },
  logout: () => {
    removeCookies(AuthHelper.AUTH_COOKIE_NAME)
    router.push('/login')
  },
}
