import type { MantineThemeColors } from '@mantine/core'
import { Barlow, Open_Sans } from 'next/font/google'

const openSans = Open_Sans({ weight: '500', subsets: ['latin'] })
const barlowHeading = Barlow({ weight: ['400', '500', '600', '800'], subsets: ['latin'] })

export const StylesHelper = {
  MOBILE: '(max-width: 720px)',
  TABLET: '(max-width: 1024px)',
  defaultFont: openSans,
  headingsFont: barlowHeading,
  colors: {
    dark: [
      '#eff6fb',
      '#dfeaf0',
      '#b9d4e4',
      '#91bcd7',
      '#70a8cc',
      '#5c9dc6',
      '#5097c4',
      '#4183ae',
      '#36749b',
      '#23648a',
    ] as MantineThemeColors['black'],
    violet: [
      '#eff1fa',
      '#dcdfef',
      '#b4bbe1',
      '#8b96d4',
      '#6877c9',
      '#5263c3',
      '#4759c0',
      '#394aa9',
      '#314199',
      '#273887',
    ] as MantineThemeColors['violet'],
    blue: [
      '#defbff',
      '#c9f2ff',
      '#98e3ff',
      '#63d2ff',
      '#37c4fd',
      '#1abcfd',
      '#00b7fe',
      '#00a1e3',
      '#0090cd',
      '#007cb5',
    ] as MantineThemeColors['blue'],
    orange: [
      '#ffeee3',
      '#ffdecc',
      '#ffba9a',
      '#ff9563',
      '#ff7536',
      '#ff6118',
      '#ff5606',
      '#e44500',
      '#cb3c00',
      '#b23100',
    ] as MantineThemeColors['orange'],
  },
}
