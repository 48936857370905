import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

// load dayjs locales
import 'dayjs/locale/fr'

import common from './fr/common.json'
import account from './fr/pages/account.json'
import briefings from './fr/pages/briefings.json'
import chat from './fr/pages/chat.json'
import clients from './fr/pages/clients.json'
import field from './fr/pages/field.json'
import index from './fr/pages/index.json'
import pollsters from './fr/pages/pollsters.json'
import programs from './fr/pages/programs.json'
import projects from './fr/pages/projects.json'
import reports from './fr/pages/reports.json'
import resourcesi18N from './fr/pages/resources.json'
import stops from './fr/pages/stops.json'
import users from './fr/pages/users.json'

export const resources = {
  fr: {
    common,
    pages: {
      account,
      briefings,
      chat,
      clients,
      field,
      index,
      pollsters,
      programs,
      projects,
      reports,
      stops,
      users,
      resources: resourcesi18N,
    },
  },
}

i18next.use(initReactI18next).init({
  lng: 'fr',
  debug: false,
  returnNull: false,
  resources,
  ns: ['common', 'pages'],
  defaultNS: 'common',
})

dayjs.extend(localizedFormat)
dayjs.locale(i18next.language)
