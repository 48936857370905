/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Test SA
 * TEST SA API Documentation
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  GetApiV1UserMe200,
  InferAttributesUserApiDTO,
  SelfUserPutApiDTO
} from './Api.schemas'
import { customInstance } from '../AxiosInstance';
import type { ErrorType } from '../AxiosInstance';




/**
 * Show self
 */
export const getApiV1UserMe = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<GetApiV1UserMe200>(
      {url: `/api/v1/user/me`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiV1UserMeQueryKey = () => {
    return [`/api/v1/user/me`] as const;
    }

    
export const getGetApiV1UserMeQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1UserMe>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1UserMe>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1UserMeQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1UserMe>>> = ({ signal }) => getApiV1UserMe(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1UserMe>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1UserMeQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1UserMe>>>
export type GetApiV1UserMeQueryError = ErrorType<unknown>

export const useGetApiV1UserMe = <TData = Awaited<ReturnType<typeof getApiV1UserMe>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1UserMe>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV1UserMeQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Update self
 */
export const putApiV1UserMe = (
    selfUserPutApiDTO: SelfUserPutApiDTO,
 ) => {
      
      
      return customInstance<InferAttributesUserApiDTO>(
      {url: `/api/v1/user/me`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: selfUserPutApiDTO
    },
      );
    }
  


export const getPutApiV1UserMeMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1UserMe>>, TError,{data: SelfUserPutApiDTO}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1UserMe>>, TError,{data: SelfUserPutApiDTO}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1UserMe>>, {data: SelfUserPutApiDTO}> = (props) => {
          const {data} = props ?? {};

          return  putApiV1UserMe(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1UserMeMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1UserMe>>>
    export type PutApiV1UserMeMutationBody = SelfUserPutApiDTO
    export type PutApiV1UserMeMutationError = ErrorType<unknown>

    export const usePutApiV1UserMe = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1UserMe>>, TError,{data: SelfUserPutApiDTO}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1UserMe>>,
        TError,
        {data: SelfUserPutApiDTO},
        TContext
      > => {

      const mutationOptions = getPutApiV1UserMeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    