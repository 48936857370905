import Axios, { AxiosError, AxiosRequestConfig, AxiosResponseHeaders } from 'axios'
import { getCookie, setCookie } from 'cookies-next'
import router from 'next/router'

import { AuthHelper } from '../helpers/Auth.helper'

export const AxiosInstance = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    'x-api-key': process.env.NEXT_PUBLIC_API_KEY,
  },
})

type ResponseHeaders = AxiosResponseHeaders & {
  'content-length': string
  'content-type': string
  'x-total-count': number
}

export type TSAResponse<T> = { headers: ResponseHeaders, body: T }

export const customInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<TSAResponse<T>> => {
  const source = Axios.CancelToken.source()
  const promise = AxiosInstance({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data, headers }) => {
    return {
      headers: headers as ResponseHeaders,
      body: data,
    }
  })

  // @ts-ignore Property 'cancel' does not exist on type 'Promise<any>'
  promise.cancel = () => {
    source.cancel('Query was cancelled')
  }

  return promise
}

AxiosInstance.interceptors.response.use(
  config => {
    // Renew auth token if made available by the API
    if (config.headers['x-jwt-token']) {
      setCookie(AuthHelper.AUTH_COOKIE_NAME, config.headers['x-jwt-token'], AuthHelper.AUTH_COOKIE_OPTIONS)
    }

    return config
  },
  error => {
    const paths = ['users', 'pollsters', 'projects']

    if (paths.some(p => router.pathname.includes(p)) && error?.response.status === 404) {
      router.push('/404')

      return Promise.reject(error)
    }

    if (paths.some(p => router.pathname.includes(p)) && error?.response.status === 403) {
      router.push('/')
    }

    return Promise.reject(error)
  })

AxiosInstance.interceptors.request.use(config => {
  if (config.headers) {
    // Add token if available
    const token = getCookie(AuthHelper.AUTH_COOKIE_NAME)
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    else {
      router.push('/login')
    }
  }

  return config
})

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>
