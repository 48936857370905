import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import type { PollsterApiDTO } from 'src/codegen/generated/Api.schemas'

type ChatStoreType = {
  isChatOpened: boolean
  pollsterId?: PollsterApiDTO['id']
}

const CHAT_KEY = 'tsa-chat'

export const useChatStore = create(
  persist<ChatStoreType>(
    () => ({
      isChatOpened: false,
      pollsterId: undefined,
    }),
    { name: CHAT_KEY },
  ),
)
