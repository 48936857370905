import styles from '@testsa/styles/layout/Layout.module.scss'
import { useTranslation } from 'react-i18next'

import { SocketContextProvider } from 'src/contexts/SocketContext'

import { MenuHelper } from '../helpers/Menu.helper'
import { Footer } from './Footer'
import { Head } from './Head'
import { Header } from './Header'

export const Layout = ({ children }: { children: JSX.Element }) => {
  const { t } = useTranslation('common')
  const links = MenuHelper.getLinks(t)

  return (
    <SocketContextProvider>
      <Head title="Admin | TEST SA" description="TEST SA" />
      <Header links={links} />
      <main className={styles.main}>{children}</main>
      <Footer links={links} />
    </SocketContextProvider>
  )
}
